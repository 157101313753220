.header-row {
  background: #c4d4d0 !important;
  font-weight: bold !important;
  font-size: 1em;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.62);
}

.header-row > * > .input > input{
  font-weight: bold !important;
  font-size: 1em;
}

.task-row {
  font-size: 0.85em;
}

.task-row > td {
  padding-top: 0.5em !important;
  padding-bottom: 0.65em !important;
}

.task-row > td:first-of-type {
  font-size: 1rem;
}

.task-row > td:first-of-type > div {
  justify-content: flex-end;
}


.resequence > input {
  border: 1px solid rgba(255, 230, 5, 0.95) !important;
  background: rgb(255, 250, 205) !important;
}

.header-row > td {
  border-top: 2px solid #5f7c74 !important;
}

/*removes the last tr's border*/
.header-row:first-of-type > td {
  border-top: none;
}

/*react table override*/
.ui.table tr.active {
  background: #badaf3 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.item-hover {
  border-left: red solid 3px !important;
}
.data-cell {
  text-align: center;
  width: 100%;
  background: transparent;
  border: none;
}

#the-grid tbody tr:nth-child(2n) {
  background-color: #eee;
}

#the-grid tbody tr:nth-child(2n + 1) {
  background-color: white;
}

#the-grid tbody tr td {
  border-left: #a0a0a0 solid 1px;
}

.sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.pinned {
  position: sticky !important;
  position: -webkit-sticky !important;
  border-right: 1px solid black;
}

#the-grid {
  margin-bottom: 200px;
}

#the-grid th {
  height: 62px;
}

#the-grid th, #the-grid td {
  position: relative;
  white-space: nowrap;
}

#the-grid td > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#the-grid th > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 4px;
  height: 60px;
  z-index: 101;
}

.header-details {
  overflow-x: hidden;
}
